// Styled list
dl.floatList {
	@include pie-clearfix;

	dt, dd {
		display: block;
		vertical-align: top;
		float: left;
	}

	dt {
		padding-right: .5rem;
		width: 40%;
		clear:left;
	}

	dd {
		width: 60%;
	}
}

// SOME HELPFUL CLASSES
//////////////////////////////

.highlight {
	color: $secondary; 
}

.btn {
	background: $medium;
	border: none;
	color: $dark;
	cursor: pointer;
	display: table;
	font-size: 1rem;
	line-height: 1rem;
	padding: .7rem 1rem;
	text-align: center;
	text-decoration: none;
	transition: 300ms all ease-in-out;

	&:hover, &:active, &:focus {
		background: $dark;
		color: $light;
	}

	*[class^="icon"] {
		margin-left: .5rem;
		vertical-align: middle;
	}
}

.notification {
	padding: 1em;
	background: $alert;
	color: $light;
	font-size: 1.2em;
}

// PAGE WRAP
//////////////////////////////

.page-wrap {
	transform: none;
}

// HEADER
//////////////////////////////

.header {
	background: $light;
	position: relative;
	width: 100%;
	background: {
		image:image-url("layout/bg-header-mobile.jpg");
		size:cover;
		position: center;
		repeat:no-repeat;
	};
	padding-top: 50px;

	@include breakpoint(medium) {
		background-image: image-url("layout/bg-header.jpg");
	}

	@include breakpoint(large) {
		padding-top: 0;
	}

	&:before {
		content: '';
		background: {
			image:image-url("layout/before-header-mobile.png");
			size:cover;
			position: center;
			repeat:no-repeat;
		};
		position: absolute;
		bottom: 0;
		left: 0;
		width: 150px;
		height: 119px;

		body:not(.index) & {
			display: none;
		}

		@include breakpoint(small) {
			background: image-url("layout/before-header-tablet.png");
			height: rem(238px);
			width: rem(300px);
		}

		@include breakpoint(giant) {
			background: image-url("layout/before-header.png");
			height: rem(462px);
			background-repeat: no-repeat;
			width: rem(582px);
		}
	}

	.top-box {
		display: none;

		@include breakpoint(medium) {
			display: block;
			background: $light;
			font-size: rem(16px);
			height: rem(33px);
			position: fixed;
			z-index: 10;
			top: 0;
			left: 0;
			width: 100%;
		}

		span {

			&.text {
				margin-right: rem(25px);

				a {
					color: $dark;

					&:hover {
						color: lighten($primary,10%);
					}
				}

				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		.social {

			a {
				text-decoration: none;

				&:first-of-type {
					margin-right: rem(10px);
				}
			}
		}
	}

	.fixed-navi {

		.branding {
			display: none;

			@include breakpoint(large) {
				display: table;
				position: relative;
				z-index: 10;
				transition:.5s;

				img {
					transition:.5s;
				}
			}
		}

		@include breakpoint(large) {
			background: rgba($light,.7);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;		
			padding: rem(20px) 0;	
			height: rem(177px);
			z-index: 1;
			margin-top: rem(33px);

			&.small {
				position: fixed;
				top: rem(33px);
				height: auto;
				background: rgba($light,.9);
				box-shadow: 0 0 rem(10px) $dark;
				z-index: 9;
				margin-top: 0;

				.row {
					align-items:center;

					.branding img {
						max-width: rem(100px);
					}
				}
			}
		}		
	}


	.claim {
		margin-top: 60%;
		margin-bottom: 15%;		

		@include breakpoint(small) {
			font-size: rem(40px);
			background: rgba($light,.8);
			padding: rem(50px) rem(25px) rem(30px);
			font-size: rem(30px);		
			margin-bottom: rem(130px);
			margin-top: 55%;	

			body:not(.index) & {
				background: none;
				margin-top: 20%;
				margin-bottom: 20%;
			}
			@include breakpoint(medium) {
				background: rgba($light,.6);
			}
		}

		@include breakpoint(giant) {
			padding: rem(40px) rem(78px) rem(20px) rem(87px);
		}

		@include breakpoint(huge) {
			margin-top: 63%;
		}

		.claim-inner {
			display: none;

			@include breakpoint(small) {
				display: block;
				max-width: rem(603px);
				line-height: rem(46px);

				body:not(.index) & {
					display: none;
				}
			}

			@include breakpoint(giant) {
				line-height: rem(58px);
			}

			span {
				display: block;

				b {
					font-weight: 700;
					font-size: rem(50px);
					text-transform: uppercase;

					@include breakpoint(giant) {
						font-size: rem(60px);
					}
				}

				&.first {
					font-weight: 700;
				}

				&.second {
					font-family: $display-font;
					font-size: rem(40px);
					font-weight: 400;
					text-align: right;

					@include breakpoint(giant) {
						font-size: rem(60px);
					}
				}

				&.third {
					font-size: rem(20px);
					text-align: right;
					font-weight: 400;

					@include breakpoint(medium) {
						font-size: rem(18px);
					}

					@include breakpoint(giant) {
						font-size: rem(20px);
					}
				}
			}
		}
	}

	.scrollTo {
		display: none;

		@include breakpoint(small) {
			display: flex;
			background: $light;
			border-radius: 100%;
			width: rem(36px);
			height: rem(36px);
			font-size: 0;
			cursor: pointer;
			transition:.5s;			
			position: absolute;
			left: 50%;
			transform:translateX(-50%);
			bottom: rem(45px);
			justify-content:center;
			align-items:center;
			border: none;
			outline: none;
		}

		body:not(.index) & {
			display: none;
		}

		&:before {
			content: '\f107';
			font-family: $icon-font;
		    font-size: rem(24px);
		    color: #3e3a00;
		    line-height: 0;
		    margin-top: 2px;
		    display: block;
		}

		&:after {
			width: 0;
			height: 0;
			position: absolute;
			content: '';
			top: 50%;
			left: 50%;	
			transform: translate(-50%, -50%);							
			transition:.8s;
		}

		&:hover {

			&:after {
				width: calc(100% + 30px);
				height: calc(100% + 30px);
				border-radius: 100%;
				background: rgba($light,.2);
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	padding-top: rem(50px);
	background: $secondary;
	position: relative;

	.padding-row {
		padding-bottom: rem(50px);

		@include breakpoint(medium) {
			padding-bottom: rem(135px);
		}
	}

	@include breakpoint(medium) {
		padding-top: rem(95px);
	}

	&:before {

		body.index & {

			@include breakpoint(huge) {
				content: '';
				background: {
					image:image-url("layout/before-main.png");
					size:cover;
					repeat:no-repeat;
					position: center;
				};
				position: absolute;
				bottom: 0;
				left: rem(-300px);
				width: rem(815px);
				height: rem(639px);
			}

			@include breakpoint(big) {
				left: rem(-200px);
			}
		}
	}

	> .row {

		&:after {

			body.index & {

				@include breakpoint(huge) {
					content: '';
					background: {
						image:image-url("layout/after-main.png");
						size:cover;
						repeat:no-repeat;
						position: center;
					};
					position: absolute;
					bottom: 0;
					right: rem(-150px);
					width: rem(302px);
					height: rem(242px);
				}
			}
		}
	}

	p {
		font-size: rem(22px);
		font-weight: 300;

		@include breakpoint(medium) {
			font-size: 1rem;
		}
	}

	span {

		&.big {
			display: block;
			font-size: rem(28px);
			font-weight: 400;
			margin-bottom: rem(20px);
		}
	}

	b, strong {

		&.coloured {
			color: $primary;
			font-weight: 400;
		}
	}

	ul {
		@extend .styled-list;
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
}

// FOOTER
//////////////////////////////

.footer {
	display: block;

	.slider-box {

		body.nojs & {
			display: none;
		}
	}

	.footer-box {
		background: {
			image:image-url("layout/bg-footer.jpg");
			size:cover;
			position: center;
			repeat:no-repeat;
		};
		padding-top: rem(50px);
		padding-bottom: rem(50px);
		text-align: right;
		border-top: rem(80px) solid $secondary;

		@include breakpoint(medium) {
			padding-top: rem(95px);
			padding-bottom: 0;
		}

		span {

			&.first-row {
				display: block;				
				font-weight: 400;

				@include breakpoint(medium) {
					font-size: rem(30px);
				}

				strong {
					font-size: 1.6rem;
					font-weight: 400;

					@include breakpoint(medium) {
						font-size: rem(44px);
					}
				}
			}

			&.second-row {
				display: block;
				margin-top: rem(20px);
				font-size: rem(36px);
				font-family: $display-font;

				.phone {
					font-size: rem(30px);
					font-family: $main-font;
					display: block;
					font-weight: 400;

					@include breakpoint(tiny) {
						display: inline-block;
					}

					@include breakpoint(small) {
						margin-right: rem($base-gap);
					}
				}

				@include breakpoint(medium) {
					margin-top: rem(40px);
				}
			}
		}
	}
}