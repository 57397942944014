// Icons
@include font-face('FontAwesome',
    'fontawesome-webfont.woff',
    'fontawesome-webfont.ttf',
    'fontawesome-webfont.svg',
    'fontawesome-webfont.eot'
);

@include font-face('robotolight',
    'roboto-light-webfont.woff',
    'roboto-light-webfont.ttf',
    'roboto-light-webfont.svg',
    'roboto-light-webfont.eot',
    $weight:300
);

@include font-face('robotolight',
    'roboto-regular-webfont.woff',
    'roboto-regular-webfont.ttf',
    'roboto-regular-webfont.svg',
    'roboto-regular-webfont.eot',
    $weight:400
);

@include font-face('robotolight',
    'roboto-bold-webfont.woff',
    'roboto-bold-webfont.ttf',
    'roboto-bold-webfont.svg',
    'roboto-bold-webfont.eot',
    $weight:700
);

@include font-face('bad_scriptregular',
    'badscript-regular-webfont.woff',
    'badscript-regular-webfont.ttf',
    'badscript-regular-webfont.svg',
    'badscript-regular-webfont.eot',
    $weight:400
);