


////////////////////////////////////////// BURGER MENU ICON ///////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////

input#toggleNav, input#contactBox {
    display: none;
}

label.menu {
    right: 0;
    width: 50%;
    background: $primary;
}

label.contacts {
    left: 0;
    width: 50%;
    background: lighten($primary,10%);

    &:after {

        @include breakpoint(tiny) {
            transition: .4s;
            opacity: 0;
            visibility: hidden;
            content: '';
            position: fixed;
            top: 50px;
            left: rem(400px);
            right: 0;
            bottom: 0;
            background: rgba(0,0,0,0.7);
        }
    }
}

label.menu {

    &:after {

        @include breakpoint(tiny) {
            transition: .4s;
            opacity: 0;
            visibility: hidden;
            content: '';
            position: fixed;
            top: 50px;
            left: 0;
            right: rem(400px);
            bottom: 0;
            background: rgba(0,0,0,0.7);
        }
    }
}

label.menu, label.contacts {
    color:$light;
    transition:.5s;
    position: fixed;
    z-index:20;
    display: flex;
    align-items:center;
    justify-content:center;
    top: 0;
    height: 50px;

    @include breakpoint(medium) {
        font-size: rem(18px);
    }

    @include breakpoint(large) {
        display: none;
    }

    &:hover, &.hovering {
        cursor: pointer;
    }

    .burger {
        width:18px;
        height:14px;
        position: relative;
        z-index: 20;         
        display: block;       

        span {
            transition:.5s;
            top:1px;
            left:0;
            height:1px;
            width: 100%;
            background:$light;
            position: absolute;
            transform-origin: top left;
        }

        &:before, &:after {
            transition:0.3s;
            position: absolute;
            left:0;
            height:1px;
            width: 100%;
            background: $light;
            display: block;
            content: '';
        }

        &:before {
            top:50%;
            transform-origin: top left;
        }

        &:after {
            bottom:0;
            transform-origin: bottom left;
        }
    }

    .menue {
        margin-left: rem(10px);
    }
}

input#toggleNav:checked ~ label.menu {

    .burger {

        &:before {
            opacity: 0;
            visibility: hidden;
        }

        &:after {
            transform:rotate(-45deg);
            margin-left: rem(7px);
        }

        span {
            transform:rotate(45deg);
            margin-left: rem(7px);
        }
    }

    &:after {
        opacity: 1;
        visibility: visible;
    }
}

input#contactBox:checked ~ label.contacts {

    &:after {
        opacity: 1;
        visibility: visible;
    }
}


////////////////////////////////////////// MOBILE NAVIGATION //////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////


input#toggleNav:checked ~ .page-navi {
    top:0;
    bottom: 0;
    right: 0;
}

input#contactBox:checked ~ .contact-box {
    top:0;
    bottom: 0;
    left: 0;
}

.contact-box {
    position: fixed;
    left: -120%;
    background:$secondary;
    transition:0.3s;
    width: 100%;
    top:0;
    z-index: 19;
    padding: 70px rem($base-gap) rem(50px);
    overflow: scroll;
    overflow-x: hidden;
    bottom: 0;
    display: flex;
    flex-direction:column;
    color: $dark;    
    text-align: center;
    align-items:center;
    justify-content:center;

    @include breakpoint(tiny) {
        width: rem(400px);
    }

    @include breakpoint(medium) {
        font-size: rem(18px);
    }

    @include breakpoint(large) {
        display: none;
    }

    .branding-mobile {
        display: table;
        margin: 0 auto;

        img {
            max-width: rem(140px);
        }
    }

    .titles {

        span {
            display: block;
            font-family: $display-font;
            font-size: rem(40px);
            margin-top: rem(50px);
            margin-bottom: rem(50px);
        }
    }

    .branding-mobile {
        display: table;

        img {
            max-width: rem(400px);
        }
    }

    address {
        font-style: normal;
        margin-top: rem(50px);
        font-size: rem(30px);
        line-height: rem(44px);

        @include breakpoint(medium) {
            font-size: rem(22px);
            line-height: rem($base-line-height);
        }
    }

    .btn {
        transition:.5s;
        background: $secondary;
        color: $primary;
        padding: rem(10px) rem($base-gap);
        font-size: rem(30px);
        margin-right: rem($base-gap);        
        margin: 0 auto;
        font-family: $main-font;
        font-weight: 300;

        &.mail {
        	font-size: rem(26px);

            @include breakpoint(tiny) {
                font-size: rem(22px);
            }
	        
	        .fa-default {
	        	margin-right: rem(5px);
	        }
        }

        @include breakpoint(medium) {
            font-size: rem(22px);
        }
    }

    .social {
    	margin-top: rem(50px);

    	a {
    		text-decoration: none;
    		margin:0 rem(5px);
    	}
    }
}

.page-navi {
    position: fixed;
    right: -120%;
    background: $light;
    transition:0.3s;
    width: 100%;
    text-align: left;
    top:0;
    z-index: 19;
    padding: 50px 0 rem(50px);
    overflow: scroll;
    overflow-x: hidden;
    bottom: 0;
    display: flex;
    flex-direction:column;
    color: $dark;    

    @include breakpoint(tiny) {
        width: rem(400px);
    }

    @include breakpoint(large) {
    	right: auto;
    	top: 0;
    	background:none;
    	width: 100%;
    	padding: 0;
    	bottom: auto;
    	flex-direction:row;
    	color: $dark;    	
    	overflow: visible;
    	justify-content:space-between;
    	position: relative;
        height: rem(134px);

        .small & {
            height: auto;
        }
    }

    nav {
    	order: 1;

    	@include breakpoint(large) {
    		width: 100%;
    	}

    	ul {
    		display: flex;
    		flex-direction:column;
    		justify-content:center;
    		width: 100%;

    		@include breakpoint(large) {
    			flex-direction:row;
    			justify-content:space-between;    
    			align-items:center;
    			position: relative;
    			font-size: rem(18px);
    		}

    		li {
    			transition:.5s;
    			background: $light;
                border-bottom: 1px solid $primary;

                @include breakpoint(medium) {
                    font-size: rem(18px);
                }

                @include breakpoint(large) {
                    background: none;
                    border: none;                    
                    position: relative;
                    padding: 0;
                    display: flex;
                    align-items:center;
                    position: relative;
                    background: none;
                    border-left: none;
                    font-weight: 700;
                    font-family: $display-font;
                    font-size: rem(24px);
                    
                    &.willkommen, &.anfahrt, &.impressum, &.datenschutzerklaerung {
                        display: none;
                    }
                }

                &.hasSub {
                    background: $primary;
                    border-bottom-color: $primary;

                    @include breakpoint(large) {
                        background: none;
                    }

                    > a, > span {
                        color: $light;

                        @include breakpoint(large) {
                            color: $dark;
                        }
                    }
                }

                .sub {            

                	li {
                        padding: 0;
                        padding-left: rem(20px);
                        border-left: none;
                        font-style: italic;
                        background: darken($primary,5%);
                        border-bottom-color: $primary;
                        font-family: $main-font;
                        font-size: rem(18px);
                        font-weight: 400;

                        &:before {
                            display: none;
                        }

                        &:last-of-type {
                			border-bottom: none;
                		}

                        a, span {
                            color: $light;
                            padding: rem(15px);

                            @include breakpoint(large) {
                                padding: rem(5px);
                            }
                        }

                        &:hover, &.active {
                        	background: $secondary;

                        	a {
                        		color: $dark;
                        	}
                        }
                    }

                    @include breakpoint(large) {
                        width:auto;
                        position: absolute;
                        top:200%;
                        opacity: 0;
                        left: 0;
                        visibility: hidden;
                        border:none;
                        transition:.5s;
                        z-index: 999;
                        white-space: nowrap;
                        background: {
                            color:$light;
                        }
                        min-width: 100%;
                        display: block;
                        text-align: left;
                        box-shadow:rem(2px) rem(2px) rem(5px) rgba(black,0.2);
                        z-index: 10;

                        li {
                            z-index: 2;
                            position: relative;
                            border-bottom:rem(1px) solid rgba($border,0.5);                   
                            transition:.5s;
                            padding: 0 rem($base-gap);

                            &.hasSub {

                                ul {
                                    right:0;
                                    transform:translate(100%);
                                }
                            }

                            a {
                                text-decoration: none;
                                display: block;
                                color:$light;
                                position: relative;  
                                transition:.5s;
                                width: 100%;

                                &:after {
                                    display: none;
                                }

                            }

                            &:after {
                                display: none;
                            }

                            &.active, &.hovering, &:hover {
                                background: $primary;

                                a {
                                    color:$light;

                                    &:before {
                                        display: none;
                                    }
                                }
                            }

                            &:last-of-type {
                                border-bottom:none;    
                            }
                        }
                    }                                        
                }    

                &.hover, &:hover {

                    > ul {
                        top:100%;
                        opacity: 1;
                        visibility: visible;
                    } 
                }                  
				
				a, span {
					text-decoration: none;
					color: $primary;
					display: block;
					padding: rem(20px) rem($base-gap);
                    transition:.5s;

                    @include breakpoint(medium) {
                        padding: rem(10px) rem($base-gap);
                    }

					@include breakpoint(large) {
						padding: 0;
                        color: $dark;
                        position: relative;

                        &:before {
                            transition:.5s;
                        }

                        &.weinbau-tagebuch {

                            &:before {
                                content: '';
                                background: {
                                    image:image-url("layout/svg/tagebuch.svg");
                                    size:cover;
                                    position: center;
                                    repeat:no-repeat;
                                };
                                position: absolute;
                                top: rem(50px);
                                left: 50%;
                                transform:translateX(-50%);
                                width: rem(120px);
                                height: rem(86px);
                            }
                        }

                        &.termine {

                            &:before {
                                content: '';
                                background: {
                                    image:image-url("layout/svg/termine.svg");
                                    size:cover;
                                    position: center;
                                    repeat:no-repeat;
                                };
                                position: absolute;
                                top: rem(55px);
                                left: 50%;
                                transform:translateX(-50%);
                                width: rem(71px);
                                height: rem(66px);
                            }
                        }

                        &.unsere-weine {

                            &:before {
                                content: '';
                                background: {
                                    image:image-url("layout/svg/weine.svg");
                                    size:cover;
                                    position: center;
                                    repeat:no-repeat;
                                };
                                position: absolute;
                                top: rem(50px);
                                left: 50%;
                                transform:translateX(-50%);
                                width: rem(80px);
                                height: rem(83px);
                            }
                        }

                        &.unser-anwesen {

                            &:before {
                                content: '';
                                background: {
                                    image:image-url("layout/svg/anwesen.svg");
                                    size:cover;
                                    position: center;
                                    repeat:no-repeat;
                                };
                                position: absolute;
                                top: rem(60px);
                                left: 50%;
                                transform:translateX(-50%);
                                width: rem(176px);
                                height: rem(92px);
                            }
                        }

                        &.kontakt {

                            &:before {
                                content: '';
                                background: {
                                    image:image-url("layout/svg/kontakt.svg");
                                    size:cover;
                                    position: center;
                                    repeat:no-repeat;
                                };
                                position: absolute;
                                top: rem(50px);
                                left: 50%;
                                transform:translateX(-50%);
                                width: rem(93px);
                                height: rem(89px);
                            }
                        }
                        
                        .small & {

                            &:before {
                                width: 0;
                                height: 0;
                            }
                        }
					}

					&.active {
						position: relative;

						&:after {
							content: 'Aktiv';
							position: absolute;
							right: rem($base-gap);
							top: 50%;
							transform:translateY(-50%);
							text-transform: uppercase;
							font-size: .7em;
							font-weight: 700;
							color: lighten($secondary,19%);
							letter-spacing: rem(1px);
							font-style: normal;
							color: $primary;

                            @include breakpoint(large) {
                                display: none;
                            }
						}
					}
				}

                @include breakpoint(huge) {
                    padding: rem(5px) rem(10px);
                }

                &:before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    transform:translateX(-50%);
                    height: rem(1px);
                    width: 0;
                    background: $dark;
                    transition:.5s;
                    top: 100%;
                }

				&:hover, &.active {
					background: $secondary;

                    &:before {
                        width: 100%;
                    }

                    a, span {
                        color: $dark;                                      
                    }

					@include breakpoint(large) {
						background: none;

                        a, span {
                            color: lighten($dark,20%);
                        }
					}					
				}
    		}
    	}
    }
}

.navadd-box {
    display: none;

    @include breakpoint(medium) {
        display: block;
        background: rgba($light,.6);
        margin-top: rem(90px);
    }
}

.navi-add {
    display: none;

    @include breakpoint(medium) {
        display: flex;
        width: 100%;
        justify-content:space-between;
        text-align: right;
        align-items:center;
        padding: rem(15px) 0;

        li {
            font-family: $display-font;

            a {
                text-decoration: none;
                font-size: rem(16px);
                color: $dark;

                &:hover, &.active {
                    color: $primary;
                }
            }
        }
    }
}