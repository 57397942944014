/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;    
    outline: none;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;        
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.slick-arrow {
    display: block;
    position: absolute;
    bottom: rem(25px);
    right: rem(90px);
    background: none;
    font-family: $icon-font;
    color: $light;
    font-size: rem(48px);
    outline:none;
    border: none;
    z-index: 1;
    cursor: pointer;

    &.slick-prev {
        right: rem(120px);

        &:before {
            content:'\f104';
        }
    }

    &.slick-next:before {
        content:'\f105';
    }
}

.slick-dots {
    display: flex !important;
    position: absolute;
    bottom: rem(15px);
    left: 50%;
    transform:translateX(-50%);
    z-index: 2;
    outline: none;

    li {
        margin: 0 rem(5px);

        &.slick-active {

            button {
                background: rgba($light,1);
            }
        }

        button {
            background: rgba($light,.5);
            border: none;
            text-indent: rem(-9999px);
            border-radius: 100%;
            height: rem(12px);
            width:rem(12px);
            outline: none;

            &:hover {
                cursor: pointer;
                background: rgba($light,.8);
            }
        }
    }
}

.slider-box {

    .slideshow {
        position: relative;

        .slide-img {
            position: relative;
            height: rem(380px);
            overflow: hidden;
            width: 100%;            

            .text-overlay {
                position: absolute;
                z-index: 2;
                background: none;
                color: $dark;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction:column;
                justify-content:center;
                text-align: center;

                &.bg-overlay {
                    background: rgba($light,.6);
                }

                .headline {
                    display: block;
                    font-family: $display-font;
                    font-size: rem(30px);
                    margin-bottom: rem(20px);                    
                }

                p {
                    margin-bottom: 0;
                    font-size: rem(20px);
                    line-height: rem(36px);
                }
            }

            img {
                position: absolute;
                top: 0;
                object-fit:cover;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }
    }
}